.recharts-cartesian-axis-tick {    
    font-family: Roboto, arial;
}

.recharts-tooltip-wrapper {
    font-family: Roboto, arial;
}

.recharts-legend-item-text {
    font-family: Roboto, arial;
}

.recharts-label tspan {
    font-family: Roboto, arial;
    font-size: 10pt;
}

.sensor-tile-item:hover {
    cursor: pointer;
}
